.universal-chart {
  margin-top: 15px;
  height: 500px;
  max-height: 500px;
  overflow:hidden;
  min-height: unset;
}
.universal-chart.show {
  height:100% !important;
  max-height: unset;
}

.universal-chart-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  > p {
    font-weight: bold;
  }
}

.global-loader-wrapper-style {
  height: 100%;

  > div {
    height: 100%;
  }

  > div > div:first-child > div, 
  > div > div:first-child > div > div {
    height: 100% !important;
  }
}

.bubbleChart__wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bubbleChart__canvasWrapper {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.bubbleChart__canvasWrapper > div {
  width: 95%;
}

.bubbleChart__yLabel {
  width: 20px !important;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 11px;
  font-weight: bold;
}

.bubbleChart__xLabel {
  margin-top: -10px;
  margin-bottom: 15px;
}

.arrow_box {
  display: flex;
  padding: 10px;
  background-color: #fff;
  flex-direction: column;
  font-size: 14px;

  > hr {
    width: 100%;
    height: 0;
    color: #f3f3f3;
    margin: 10px 0;
  }

  > span {
    margin: 4px 0;
  }
}

.metric-select-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.panel-body-sum-container {
  margin-top: 10px;
  margin-left: -5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.panel-body-sum {
  margin: 20px;
  padding: 20px;
  border: 1px solid #444;
  border-radius: 7px;
}

.panel-body-sum-container .panel-body-sum {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 4px;
  background-color: #2d353c;
  color: #fefefe;
  margin: 5px;
  padding: 15px;
  text-align: center;
  justify-content: center;
  width: 235px;

  span,
  h3 {
    color: #ffffff;
  }
}

.panel-body-sum-container .panel-body-sum h3 {
  text-transform: lowercase;
}

.apexcharts-svg {
  * {
    font-family: "Roboto", sans-serif !important;
  }
}

.apexcharts-legend-text,
.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: "Roboto", sans-serif !important;
}

.universal-chart {
  .ant-spin-nested-loading {
    height:100%;
    .ant-spin-container {
      height:100%;
    }
  }
}

/*
.chartComponent {
  overflow:hidden !important;
  .ant-spin-nested-loading {
    height:100%;
    .ant-spin-container {
      height:100%;
    }
  }
}
*/
