.siderChildren {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.menu {
  position: fixed;
  top: 50px;
  left: 0;

  width: 370px;

}

.schoolSelectorWrapper {
  min-height: 65px;
  border-bottom: 1px solid rgb(39, 49, 53);
  padding-bottom: 10px;
  margin-bottom: 10px;

  > ul {
    padding-top: 12px;
  }
}
.schoolName{
    li{
      height:auto !important;
    }
   div {
    height: auto !important;
  }
}

.schoolName .ant-menu-item-active {
  background-color: none !important;
}

.ant-drawer-mask,
.ant-drawer-content-wrapper {
  transition: none !important;
}

.activeItem{
  color: rgb(0, 0, 0);
}

:global {
  .ant-menu-light {
    .ant-menu-item-selected {
      // Добавляем более специфичный селектор
      &:not(:disabled):not(.ant-menu-item-disabled) {
        background-color: #ffffff !important;
        color: #000000 !important;
        border-radius: 30px;
        // padding-left: 7px;
      }
      
      // Стили для вложенных элементов
      .ant-menu-item-icon + span,
      .anticon + span {
        color: inherit !important; // Наследуем цвет от родителя
      }
    }

    .ant-menu-item{
      padding-left: 11px !important;
    }

    .ant-menu-item-active {
      background-color: #fbfbfb !important;
      border-radius: 30px;
      color: #000000 !important;
    }

  }
}

:global(.ant-menu-item-selected)::after {
  display: none !important;
  content: none !important;
}

:global(.ant-layout-sider-children) {
  background-color: #f3f3f3 !important;
  width: 185px;
  // width: 229px;

}
:global(.ant-layout-sider-trigger) {
  background-color: #f3f3f3 !important;
  svg {
    fill: rgb(0, 0, 0) !important; // Изменить цвет

  }
}
// :global(.ant-menu-item-selected) {
//   background-color: #f3f3f3 !important;
// }

.navTabs {
  background-color: #f3f3f3 !important;
}


.navFrame{
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40%;
  background-color: #fff;
  box-shadow: -2px 0 8px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
}

.navOverlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.navMenu {
  background: #f3f3f3; 
  
  &.fixed {
    position: fixed;
    top: 0;
    // z-index: 1000;
    width: 185px; // Ширина вашего сайдбара
    height: 100vh;
    overflow-y: auto;
  }
}

