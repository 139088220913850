$border-color: #d9d9d9;
$hover-bg: #f5f5f5;
$overlay-bg: rgba(0, 0, 0, 0.5);
$shadow: 2px 0 8px rgba(0, 0, 0, 0.15);

.side-menu {
    position: fixed;
    top: 0;
    left: 173px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;

    &-content {
        width: 440px;
        background-color: white;
        padding: 0px;
        padding-top: 9px;
        padding-right: 0;
        overflow-y: auto;
    }

    &-overlay {
        flex: 1;
        background-color: $overlay-bg;
    }
}

.menu {
    &-section {
		display: flex;
		flex-direction: column;
		padding: 0 0 10px 10px;
        margin-bottom: 40px;
        border-bottom: 1px solid $border-color;

        &:last-child {
            border-bottom: none;
        }
    }

    &-title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
    }

    &-item {
        text-align: left;
        font-size: 17px;
        padding: 8px 0 8px 30px;
        cursor: pointer;
        // Добавьте эти стили
        color: inherit; // Наследует цвет от родительского элемента
        text-decoration: none; // Убирает подчеркивание

        &:hover {
            background-color: #fbfbfb;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            color: #000
        }

        &.active {
            background-color: #f3f3f3;
            color: #000000;
            font-weight: 500;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
        }
    }
}