$border-color: #d9d9d9;
$hover-bg: #f5f5f5;
$overlay-bg: rgba(0, 0, 0, 0.5);
$shadow: 2px 0 8px rgba(0, 0, 0, 0.15);

.side-menu {
    position: fixed;
    top: 0;
    left: 172px;

    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;

    &-content {

        width: 320px;
        background-color: white;
        padding: 16px;
        padding-top: 9px;
        padding-right: 0px;

        overflow-y: auto;
    }

    &-overlay {
        flex: 1;
        background-color: $overlay-bg;
    }
}

.menu {
    &-section {
		padding: 0 0 10px 10px;

        margin-bottom: 10px;


        &:last-child {
            border-bottom: none;
        }
    }

    &-title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
    }

    &-item {
        text-align: left;
        font-size: 17px;
        padding: 8px 0 8px 30px;
        cursor: pointer;

        &:hover {
            // background-color: $hover-bg;
            background-color: #fbfbfb;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;

            color: #000000 !important;

        }

		&.active {
            background-color: #f3f3f3;
            color: #000000;
            font-weight: 500;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
        }
    }
}