
$border-color: #d9d9d9;
$hover-bg: #f5f5f5;
$overlay-bg: rgba(0, 0, 0, 0.5);
$shadow: 2px 0 8px rgba(0, 0, 0, 0.15);

.tab-content {
	position: fixed;
	left: 491px;
	top: 0;
	height: 100vh;
	width: 30%;
	background-color: #ffffff;
	// background-color: #f3f3f3;
	border-right: 1px solid #ddd;
	border-left: 1px solid #ddd;
	padding: 16px;
	padding-right: 0px;
	overflow-y: auto;
	z-index: 1000;
	animation: slideIn 0.3s ease-out forwards;
	/* Удалено transform */
	opacity: 0;
	/* Начальная прозрачность */

	animation-fill-mode: forwards;
}

@keyframes slideIn {
	from {

		opacity: 0;
		/* Начальная прозрачность */
	}

	to {
		opacity: 1;
		/* Конечная прозрачность */
	}
}


.linkItem {
	text-align: left;
	font-size: 17px;
	padding: 8px 0 8px 30px;
	cursor: pointer;
	// Добавьте эти стили
	color: #000000 !important; // Наследует цвет от родительского элемента
	text-decoration: none; // Убирает подчеркивание

}
.linkItem a {
	color: #000000;
}

.linkItem a:hover {
	color: #000000 !important;
}
.linkItem:hover {
	background-color: $hover-bg;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
}
.linkItem.active {
	background-color: #f3f3f3;
	color: #000000;
	font-weight: 500;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
}

.customModal .ant-modal-header {
	border-bottom: none !important;
  }
