.params {
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 1250px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.Dashboard_Fixed {
  width: 100%;

  div.ant-space-item {
    display: none;
    background-color: black;
    align-self: flex-end;
  }
}