.universal-table {
  position: relative;
  box-sizing: border-box;
}

.universal-table-settings {
  background-color: #f3f3f3;
  display: flex;
  padding: 7px 15px;
}

.table-expanded-indent {
  height: 1px;
  float: left;
}
.table-expanded-loading,
.table-expanded-icon {
  margin-right: 8px;
  font-size: 17px;
  float: left;
  line-height: 0;
  margin-top: 0 !important;
}

.table-expanded-icon {
  cursor: pointer;
}

.table-expanded-icon.spaced {
  visibility: hidden;
  cursor: default;
}

.table-header-cell {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 7px 0;
  width: 100%;
  justify-content: flex-end;
}

.table-header-cell > div:nth-child(1) {
  height: 100% !important;
}

.BaseTable__table-main .BaseTable__header {
  background-color: transparent;
}
.BaseTable__header-row {
  background-color: transparent;
}
.BaseTable__row--frozen {
  background-color: #f3f3f3;
}
.table-header-sort {
  display: inline;
  cursor: pointer;
}
.table-header-sort-right.sort-asc::after,
.table-header-sort-right.sort-desc::after {
  left: unset !important;
  right: 10px !important;
}

.table-header-sort.sort-asc::after,
.table-header-sort.sort-desc::after {
  position: absolute;
  top: 6px;
  left: 15px;
  color: #1890ff;
}
.table-header-sort.sort-asc::after {
  content: "▲";
}
.table-header-sort.sort-desc::after {
  content: "▼";
}
.table-header-cell-question {
  font-size: 12px;
  /*color: #ccc;*/
  margin-right: 3px;
  cursor: pointer;
}
.table-header-cell-question > .anticon {
  display: inline-flex;
}
.table-overlay {
  height: calc(100% + 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.table-row-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.table-row-empty .ant-empty-normal {
  margin: 0;
}
.table-header-cell-buttons {
  padding-bottom: 12px;
  justify-content: flex-end;
}
.table-header-cell-buttons:hover {
  opacity: 1;
}

.listpicker-item {
  overflow-y: hidden;
  margin-bottom: 7px;
}

.listpicker-item.listpicker-item-hide {
  overflow-y: hidden;
  height: 0;
  margin-bottom: 0;
}

.picker-counter-element {
  background-color: #ff3f2f;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal-settings .drag-item {
  box-sizing: border-box;
  display: inline-block;
  line-height: 28px;
  margin: 0 3px 4px 0;
  padding-right: 20px;
  border: solid 1px #ddd;
  border-radius: 3px;
  white-space: nowrap;
  cursor: move;
  position: relative;
  width: 100%;
}
.modal-settings .drag-handler {
  display:flex;
  width:100%;
  align-items: center;
}
.modal-settings .drag-handler .anticon {
  margin: 0 3px;
}

.modal-settings .drag-handler .drag-label {
  padding-left:6px;
  display: inline-block;
  /*max-width: 250px;*/
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 7px;
  vertical-align: middle;
  font-size: 14px;
}

.modal-settings .drag-remove {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 6px;
  border-left: #ddd solid 1px;
  cursor: pointer;
  opacity: 0.5;
  height: 30px;
  line-height: 24px;
}

.modal-settings .drag-remove:hover {
  opacity: 1;
}

.modal-settings .drag-remove .anticon {
  padding-top: 8px;
}
.BaseTable__header-row {
  position: sticky;
  bottom: 0;
  background: white;
  font-weight: bold;
  z-index: 10;
}

.BaseTable__row-cell {
  padding: unset !important;
  padding-left:2px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  position: relative;
}

.customTabs {

  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-tab {
    color: black;
    padding: 8px 16px;
    border: 1px solid #f0f0f0;;
    margin-top: -1px !important;
    margin-bottom: 0 !important;

    &.ant-tabs-tab-active {
      background-color: #1ba1e2;
      border-left: 2px solid #1ba1e2;;
      div {
        color: white;
      }
    }

    &:hover {
      color: #1ba1e2;
    }
  }
  .ant-tabs-tabpane {
    padding-top:20px;
  }

  .ant-tabs-tab-btn {
    color: inherit;
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}
.custom-modal {
  .ant-modal-body {
    padding-top:0;
    padding-left:0;
  }
  .ant-modal-header {
    border-bottom: 1px solid #f0f0f0;
  }
}
